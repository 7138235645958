
import {defineComponent} from 'vue'

export default defineComponent({
  name: "PhoneAnalysisPart",
  props:  {
    callData: {}
  }

})
