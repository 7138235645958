
import {defineComponent, ref} from 'vue'
import EntityLoading from "@/components/base/loading/EntityLoading.vue";
import ActivityService from "@/core/services/ActivityService";
import QuickAction from "@/components/base/action/QuickAction.vue";
import Swal from "sweetalert2";
import AlParts from "@/views/activity/AlParts.vue";
import PhoneAnalysisPart from "@/views/activity/PhoneAnalysisPart.vue";

export default defineComponent({
  name: "CallAnalysis",
  components: {PhoneAnalysisPart, QuickAction, EntityLoading, AlParts},
  props: {
    id: {},
  },
  setup(props) {
    const data = ref<any>([]);
    const loading = ref(false);
    const load = (id) => {
      loading.value = true
      ActivityService.getAnalytics(id).then(res => {
        data.value = res;
      }).finally(() => {
        loading.value = false
      })
    }
    load(props.id);
    return {
      loading,
      data,
    }

  },
  methods: {
    call() {
      Swal.fire({
        heightAuto: false,
        onOpen: () => {
          Swal.showLoading()
        },
      });
      ActivityService.processAnalytics(this.id).then(res => {
        this.data = res
      }).finally(() => {
        Swal.close();
      })
    },
    build(data) {
      return JSON.parse(data)
    },
    format(json) {
      let view = json
      view = view.replaceAll("````json", "")
      view = view.replaceAll("```", "")
      view = view.replaceAll("json", "")
      console.log(view)
      return JSON.parse(view)
    }
  }

})
